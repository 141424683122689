import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Layout, Typography, Row, Col, Input, List } from 'antd';
import HeaderComponent from '../components/header/header';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;

const nearest = (NEAREST_URL, pointBase = {}, query) => {
  const lat = pointBase.lat || pointBase.latitude;
  const lon = pointBase.lon || pointBase.longitude;

  const data = JSON.stringify({ lat, lon, query: query });

  const config = {
    method: 'post',
    url: NEAREST_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(({ data }) => {
      return data.body.places;
    })
    .catch(error => {
      console.log(
        'Error intentando esto, es red? hacer aqui un dispatch para presentar un toast ',
        error
      );
    });
};

const route = (mapboxToken, from, to, dispatch) => {
  const query = `${from.lon},${from.lat};${to.lon},${to.lat}?&geometries=geojson&access_token=${mapboxToken}`;
  const config = {
    method: 'GET',
    url: 'https://api.mapbox.com/directions/v5/mapbox/driving/' + query,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  axios(config)
    .then(({ data }) => {
      if (data && data.routes && data.routes.length) {
        dispatch({
          type: 'SET_ROUTE',
          data: data.routes[0],
        });
      }
    })
    .catch(error => {
      console.log(
        'Error intentando esto, es red? hacer aqui un dispatch para presentar un toast ',
        error
      );
    });
};

const LocationsPage = pageRoute => {
  const location = pageRoute.location;
  const locationToSet = (location.state || { location: 'from' }).location;
  const title = location.state ? location.state.title : '';

  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);

  const { NEAREST_URL, MAPBOX_TOKEN } = useSelector(state => state.config);
  const currentLocation = useSelector(state => state.currentLocation);
  const locations = useSelector(state => state.locations) || {};

  useEffect(() => {
    if (currentLocation) {
      setPlaces([
        {
          lat: currentLocation.latitude,
          lon: currentLocation.longitude,
          name: currentLocation.city,
        },
      ]);
    }
  }, []);

  const dispatch = useDispatch();

  const delayedQuery = useCallback(
    _.debounce(q => searchThis(q), 500),
    []
  );

  const searchThis = async value => {
    const pointBase = currentLocation ? currentLocation : locations.from;

    if (value.length > 2 && !loading) {
      setLoading(true);
      const places = await nearest(NEAREST_URL, pointBase, value);
      setPlaces(places);
      setLoading(false);
    }
  };

  const back = () => {
    pageRoute.navigate('/wizard');
  };

  const selectPlace = item => {
    const locat = { ...locations, [locationToSet]: item };
    dispatch({
      type: 'SET_LOCATION',
      data: {
        [locationToSet]: item,
      },
    });

    if (locat.from && locat.to) {
      route(MAPBOX_TOKEN, locat.from, locat.to, dispatch);
    }

    back();
  };

  return (
    <Layout className="site-layout-background">
      <HeaderComponent title={title} onClick={() => back()} />
      <Content className="site-content">
        <Row justify="center" aling="center">
          <Col span={24}>
            <Text className="titles" level={4} strong>
              Ingresar el nombre de una ciudad o lugar
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Search onChange={e => delayedQuery(e.target.value)} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <List
              bordered
              loading={loading}
              dataSource={places}
              renderItem={(item, i) => (
                <List.Item className="place" key={i} onClick={() => selectPlace(item)}>
                  <Typography.Text>{item.name}</Typography.Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

LocationsPage.propTypes = {
  location: PropTypes.object,
};

export default LocationsPage;
